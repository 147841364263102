import { atom } from "recoil"
import { recoilPersist } from "recoil-persist"
import { StatsMode } from "../useStats"

const { persistAtom } = recoilPersist({ key: "statsModeState" })

export const statsModeState = atom<StatsMode>({
  key: "statsModeState",
  default: "real-time",
  effects: [persistAtom],
})
