import { classNames } from "@today/lib"
import { useStyletron } from "baseui"
import { Menu } from "baseui/icon"
import Link from "next/link"
import { PropsWithChildren, useEffect } from "react"
import { useRecoilState } from "recoil"
import { ENV } from "../../config"
import { showsSideNavigationState } from "../../states/showsSideNavigationState"
import { FaKey } from "react-icons/fa6"
import { Checkbox } from "baseui/checkbox"
import { statsModeState } from "../../states/statsModeState"
import { useTheme } from "../ThemeProvider"
import { StatefulTooltip } from "baseui/tooltip"

export function TopNavigation() {
  const [css] = useStyletron()
  const [showsSideNavigation, setShowsSideNavigation] = useRecoilState(
    showsSideNavigationState
  )
  useEffect(() => {
    setShowsSideNavigation(window.screen.width > 1024)
  }, [setShowsSideNavigation])

  const [statsMode, setStatsMode] = useRecoilState(statsModeState)

  const { theme } = useTheme()

  return (
    <div className="bg-white">
      {ENV !== "prod" && (
        <AttentionRibbon className="bg-red-500">
          {ENV.toUpperCase()} 환경입니다.&nbsp;
          <a className="underline" href="https://portal.vtov.studio">
            운영 환경 바로가기
          </a>
        </AttentionRibbon>
      )}
      <div className="relative w-full">
        <div className="flex items-center border-b border-gray-200 px-4 py-4">
          <button onClick={() => setShowsSideNavigation(!showsSideNavigation)}>
            <Menu size={24} />
          </button>
          <div className="ml-4 flex w-full items-center justify-between">
            <div className="text-2xl font-semibold">
              <Link href="/" passHref>
                <a>
                  <span className="hidden sm:inline">To-Day </span>Portal
                </a>
              </Link>
            </div>
            <StatefulTooltip
              popoverMargin={5}
              showArrow
              placement="left"
              content={
                statsMode === "real-time"
                  ? "내부 실질 통계 적용 중으로, 외부 공유가 금지됩니다."
                  : "외부 공유용 통계 표시 중으로, 실질 통계와 차이가 있습니다."
              }
            >
              <div>
                <Checkbox
                  overrides={{
                    Root: {
                      style: {
                        display: "flex",
                        alignItems: "center",
                      },
                    },
                    Label: {
                      style: {
                        marginRight: "-2px",
                        paddingRight: 0,
                        paddingBottom: "1px",
                      },
                    },
                    ToggleTrack: {
                      style: {
                        width: "32px",
                        backgroundColor:
                          statsMode === "real-time"
                            ? theme.colors.green[600]
                            : theme.colors.gray[400],
                      },
                    },
                    Toggle: {
                      style: {
                        width: "10px",
                        height: "10px",
                        transform:
                          statsMode === "real-time"
                            ? "translateX(20px)"
                            : "translateX(2px)",
                        backgroundColor: theme.colors.gray[100],
                      },
                    },
                  }}
                  checkmarkType="toggle"
                  checked={statsMode === "real-time"}
                  onChange={(event) =>
                    setStatsMode(
                      event.target.checked ? "real-time" : "report-time"
                    )
                  }
                >
                  <FaKey
                    className={classNames(
                      statsMode === "real-time"
                        ? "text-green-600"
                        : "text-gray-500",
                      "p-0"
                    )}
                  />
                </Checkbox>
              </div>
            </StatefulTooltip>
          </div>
          <div className="flex-1" />
        </div>
        <div className="absolute right-0 top-0">
          <div
            className={css({
              [`@media screen and (max-width: 1549px)`]: {
                marginTop: "0.8rem",
                marginRight: "0.25rem",
                maxWidth: "80px",
              },
              [`@media screen and (min-width: 1550px)`]: {
                marginTop: "1.25rem",
                marginRight: "1.25rem",
              },
            })}
          ></div>
        </div>
      </div>
    </div>
  )
}
function AttentionRibbon({
  className,
  children,
}: PropsWithChildren<{
  className: string
}>) {
  return (
    <div
      className={classNames(
        "py-1 text-center text-sm font-semibold text-white",
        className
      )}
    >
      {children}
    </div>
  )
}
