import { Address, Location, LocationContext, Organization, PolicyType } from "."
import { DeliveryClass, EndUserType, ShippingType } from "../../common"

export interface Load {
  id: string
  invoiceNumber: string
  clientId?: string
  clientName: string
  clientOrderId?: string
  clientShippingId?: string
  name: string
  state:
    | "PENDING_COLLECT"
    | "HOLDING_COLLECT"
    | "IN_COLLECT"
    | "IN_TRANSPORT"
    | "IN_DELIVERY"
    | "HOLDING_DELIVERY"
    | "AT_STATION"
    | "DELIVERED"
    | "QUIT"
    | "LOST"
  substate: "BEFORE_SORTING" | "STAND_BY" | "RESUMABLE" | null
  deliveryClass: DeliveryClass
  shippingType: ShippingType
  endUserType: EndUserType
  orderTime: string
  takeOutTime: string | null
  readyTime: string | null
  standByTime: string | null
  targetDeliverTime: string | null
  cartId: string | null
  truckId: string | null
  plannedTruckId: string | null
  stationId: string | null
  scannedStationId: string | null
  shippingPath: {
    routeId: string
    sourceStation: {
      id: string
      sequence: number
    } | null
    targetStation: {
      id: string
      sequence: number
    } | null
    finished: boolean
  }[]
  sourceStationId: string | null
  targetStationId: string | null
  sender: EndUserInfo
  receiver: EndUserInfo
  deliveryInfo: {
    deliverTime: string
    imageUrl: string
    handOverMethod: string
    specialNote: string
    location?: LocationContext
    type?:
      | "FORCE_DELIVERY"
      | "FIX_WRONG_DELIVERY"
      | "BEFORE_DELIVERY"
      | "FIX_BEFORE_DELIVERY"
    extraPayload?: any
  } | null
  holdDeliveryInfo: {
    holdTime?: string
    imageUrl: string
    type: HoldDeliveryType
    reason: string
    specialNote: string
    location: LocationContext
    extraPayload?: {
      holdOrQuitTypeId?: string
      memo?: string
    }
  } | null
  holdPickUpInfo: {
    holdTime?: string
    imageUrl: string
    type: HoldPickUpType
    reason: string
    specialNote: string
    location: LocationContext
    extraPayload?: {
      holdOrQuitTypeId?: string
      memo?: string
    }
  } | null
  unholdDeliveryInfo: {
    unholdTime: string
    unholdCount: number
    extraPayload: any
  } | null
  unholdPickUpInfo: {
    unholdTime: string
    unholdCount: number
    extraPayload: any
  } | null
  pickUpInfo: {
    pickUpTime?: string
    imageUrl?: string
    handOverMethod: string
    specialNote: string
    extraPayload: any
    location: LocationContext
  } | null
  quitInfo: {
    quitTime: string
    reason: QuitReasonType
    specialNote: string
    extraPayload: any
  } | null
  lastPossessor: {
    organizationId: Organization["id"] | ""
    driverId: string
    driverName: string
    driverPhone: string
  }
  forwardingInfo?: {
    brokerOrganizationName: string
    deliveryOrganizationId: string
    deliveryOrganizationName: string
    handoverTime: string
    invoiceNumber: string
  }
  developerPayload?: string
  reportTimes: {
    deliverTime?: string
    aheadDeliverTime?: string
  }
  scannedStationIds: string[]
  // XXX: 알림톡 대표 화물 정보인 경우 아래 필드들이 추가됨
  grouped?: boolean
  reserved?: boolean
}

interface EndUserInfo {
  shippingPlaceId?: string
  name: string
  phone: string
  rawAddress: string
  address: Address
  location: Location
  lastMileInfo: {
    regionId: string
    regionSetPolicyType: PolicyType
    regionSetName: string
  }
  accessMethod?: string
  preference?: string
}

export type LoadFilterType = string | ((load: Load) => boolean)

export type HoldDeliveryType =
  | "REFUSED"
  | "DATE_CHANGED"
  | "ADDRESS_CHANGED"
  | "WRONG_ADDRESS"
  | "DAMAGED"
  | "MISCLASSIFIED"
  | "DELAYED"
  | "WRONG_PASSWORD"
  | "CANNOT_FIND_ENTRANCE"
  | "ACCESS_DENIED"
  | "LOSS_CONCERNED"
  | "BAD_ENVIRONMENT"
  | "CANNOT_DO_REQUEST"
  | "LOST"

export type HoldPickUpType =
  | "CANCELED"
  | "DATE_CHANGED"
  | "ADDRESS_CHANGED"
  | "NO_LOAD"
  | "OTHER_COMPANY_LOAD"
  | "BAD_PACKING"
  | "CANNOT_DISTINGUISH_LOAD"
  | "WRONG_PASSWORD"
  | "ACCESS_DENIED"
  | "BAD_ENVIRONMENT"
  | "CANNOT_DO_REQUEST"

export type QuitReasonType = "DAMAGED" | "LOST" | "CLIENT_REQUEST" | "TEST"

export function formatQuitReason(type: QuitReasonType) {
  switch (type) {
    case "DAMAGED":
      return "물품 파손"
    case "LOST":
      return "물품 분실"
    case "CLIENT_REQUEST":
      return "고객사 요청"
    case "TEST":
      return "테스트"
    default:
      return type
  }
}
